import React from "react";

const createAsync = (name) => {
  return React.lazy(() => import(`./Pages/${name}`));
};

const routes = [
  {
    path: "/",
    component: createAsync("Home"),
    exact: true,
  },
  {
    path: "/who-we-are",
    component: createAsync("WhoWeAre"),
    exact: true,
  },
  {
    path: "/process-we-follow",
    component: createAsync("ProcessWeFollow"),
    exact: true,
  },
  {
    path: "/process-we-follow/:id",
    component: createAsync("ProcessWeFollow"),
    exact: true,
  },
  {
    path: "/careers",
    component: createAsync("Careers"),
    exact: true,
  },
  {
    path: "/our-team",
    component: createAsync("OurTeam"),
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: createAsync("PrivacyPolicy"),
    exact: true,
  },
  {
    path: "/pricing-policy",
    component: createAsync("PricingPolicy"),
    exact: true,
  },
  {
    path: "/terms-and-conditions",
    component: createAsync("TermsAndCondition"),
    exact: true,
  },
  {
    path: "/shipping-policy",
    component: createAsync("ShippingPolicy"),
    exact: true,
  },
  {
    path: "/refund-policy",
    component: createAsync("RefundPolicy"),
    exact: true,
  },
  {
    path: "/cancellation-policy",
    component: createAsync("CancellationPolicy"),
    exact: true,
  },
  {
    path: "/Job-details-inner-page",
    component: createAsync("JobDetailsInnerPage"),
    exact: true,
  },
  {
    path: "/products/food-delivery",
    component: createAsync("FoodDelivery"),
    exact: true,
  },
  {
    path: "/products/lms-udemy",
    component: createAsync("LMSUdemy"),
    exact: true,
  },
  {
    path: "/products/payment-wallet-management",
    component: createAsync("PaymentWalletManagement"),
    exact: true,
  },
  {
    path: "/products/banking-co-operative-bank",
    component: createAsync("BankingCoOperativeBank"),
    exact: true,
  },
  {
    path: "/service/flutter-mobile-app-services",
    component: createAsync("FlutterMobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/ios-mobile-app-services",
    component: createAsync("IOSMobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/android-mobile-app-services",
    component: createAsync("AndroidMobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/reactNative-mobile-app-services",
    component: createAsync("ReactNativeMobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/ionic-mobile-app-services",
    component: createAsync("IonicMobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/xamarin-mobile-app-services",
    component: createAsync("XamarinMobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/php-web-and-cms-services",
    component: createAsync("PhpWebAndCMSDevelopment"),
    exact: true,
  },
  {
    path: "/service/laravel-web-and-cms-services",
    component: createAsync("LaravelWebAndCMSDevelopment"),
    exact: true,
  },
  {
    path: "/service/wordpress-web-and-cms-services",
    component: createAsync("WordpressWebAndCMSDevelopment"),
    exact: true,
  },
  {
    path: "/service/python-web-and-cms-services",
    component: createAsync("PythonWebAndCMSDevelopment"),
    exact: true,
  },
  {
    path: "/service/cakephp-web-and-cms-services",
    component: createAsync("CakephpWebAndCMSDevelopment"),
    exact: true,
  },
  {
    path: "/service/joomla-web-and-cms-services",
    component: createAsync("JoomlaWebAndCMSDevelopment"),
    exact: true,
  },
  {
    path: "/service/iot-apps-development-services",
    component: createAsync("IOTAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/embeddedSoftware-iot-and-embedded-services",
    component: createAsync("EmbeddedSoftwareIOTAndEmbedded"),
    exact: true,
  },
  {
    path: "/service/iotHardwarePrototyping-iot-and-embedded-services",
    component: createAsync("IOTHardwarePrototypeIOTAndEmbedded"),
    exact: true,
  },

  {
    path: "/service/iotDashboardAndAnalytics-iot-and-embedded-services",
    component: createAsync("IOTDashboardAndAnalyticsIOTAndEmbedded"),
    exact: true,
  },
  {
    path: "/service/smartHomeAutomation-iot-and-embedded-services",
    component: createAsync("SmartHomeAutomationIOTAndEmbedded"),
    exact: true,
  },
  {
    path: "/service/magento-ecommerce-services",
    component: createAsync("Magento"),
    exact: true,
  },
  {
    path: "/service/bigcommerce-ecommerce-services",
    component: createAsync("BigCommerce"),
    exact: true,
  },
  {
    path: "/service/ubercart-ecommerce-services",
    component: createAsync("Ubercart"),
    exact: true,
  },
  {
    path: "/service/cs-cart-ecommerce-services",
    component: createAsync("CSCart"),
    exact: true,
  },
  {
    path: "/service/prestashop-ecommerce-services",
    component: createAsync("Prestashop"),
    exact: true,
  },
  {
    path: "/service/shopify-ecommerce-services",
    component: createAsync("Shopify"),
    exact: true,
  },
  {
    path: "/service/woocommerce-ecommerce-services",
    component: createAsync("WooCommerce"),
    exact: true,
  },
  {
    path: "/service/blockchain-development-services",
    component: createAsync("BlockchainServices"),
    exact: true,
  },
  {
    path: "/service/salesforce-development-service",
    component: createAsync("SalesforceDevelopment"),
    exact: true,
  },
  {
    path: "/products/zoomative-autodealer-management-solution",
    component: createAsync("Zoomative_AutodealerManagementSolution"),
    exact: true,
  },
  {
    path: "/service/artificial-intelligence-services",
    component: createAsync("ArtificialIntelligence"),
    exact: true,
  },
  {
    path: "/service/iot-development-services",
    component: createAsync("IotDevelopment"),
    exact: true,
  },
  {
    path: "/service/mobile-app-development-services",
    component: createAsync("MobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/service/software-testing-services",
    component: createAsync("SoftwareTesting"),
    exact: true,
  },
  {
    path: "/service/embedded-software-services",
    component: createAsync("EmbeddedSoftware"),
    exact: true,
  },
  {
    path: "/service/AR-solution-services",
    component: createAsync("ARSolutionServices"),
    exact: true,
  },
  {
    path: "/service/machine-learning-services",
    component: createAsync("MachineLearning"),
    exact: true,
  },
  {
    path: "/service/startup-support-services",
    component: createAsync("StartupSupport"),
    exact: true,
  },
  {
    path: "/service/product-prototyping-services",
    component: createAsync("ProductPrototyping"),
    exact: true,
  },
  {
    path: "/products/studio-erp",
    component: createAsync("StudioERP"),
    exact: true,
  },
  {
    path: "/products/cubevo-crm",
    component: createAsync("CubevoCRM"),
    exact: true,
  },
  {
    path: "/products/fabrexa-fabric-erp",
    component: createAsync("FabrexaFabricERP"),
    exact: true,
  },
  {
    path: "/products/billient-accounting-management-solution",
    component: createAsync("BillientAccountingManagementSolution"),
    exact: true,
  },
  {
    path: "/products/dimexa-customized-erp",
    component: createAsync("DimexaCustomizedERP"),
    exact: true,
  },
  {
    path: "/products/dimeco-diamond-jewellery-erp",
    component: createAsync("DimecoDiamond_JewelleryERP"),
    exact: true,
  },
  {
    path: "/products/transida-transportation-erp",
    component: createAsync("TransidaTransportationERP"),
    exact: true,
  },
  {
    path: "/products/hireDevops-dedicated-dev-portal",
    component: createAsync("HireDevopsDedicatedDevPortal"),
    exact: true,
  },
  {
    path: "/products/migicest-real-estate-portal",
    component: createAsync("MigicestRealEstatePortal"),
    exact: true,
  },
  {
    path: "/products/bizvyapar-b2b-portal",
    component: createAsync("BizvyaparB2BPortal"),
    exact: true,
  },
  {
    path: "/products/stockforo-stock-market",
    component: createAsync("StockforoStockMarket"),
    exact: true,
  },
  {
    path: "/products/ignitive-lims-solution",
    component: createAsync("IgnitiveLIMSSolution"),
    exact: true,
  },
  {
    path: "/products/medicive-hospital-management-solution/pharma-company",
    component: createAsync("MediciveHospitalManagement"),
    exact: true,
  },
  {
    path: "/products/scholar-erp-school-management-solution",
    component: createAsync("ScholarErpSchoolManagementSolution"),
    exact: true,
  },
  {
    path: "/service/iot-development-services",
    component: createAsync("IotDevelopment"),
    exact: true,
  },
  {
    path: "/products/urban-clap",
    component: createAsync("UrbanClap"),
    exact: true,
  },
  {
    path: "/products/construx-erp-construction-management-solution",
    component: createAsync("ConstruxERPConstructionManagement"),
    exact: true,
  },
  {
    path: "/Android-App-Development-solution",
    component: createAsync("AndroidAppDevelopment"),
    exact: true,
  },
  {
    path: "/ReactNative-App-Development-solution",
    component: createAsync("ReactNativeAppDevelopment"),
    exact: true,
  },
  {
    path: "/Ionic-App-Development-solution",
    component: createAsync("IonicAppDevelopment"),
    exact: true,
  },
  {
    path: "/products/milk-dairy-rice-mill",
    component: createAsync("MilkDairyRiceMill"),
    exact: true,
  },
  {
    path: "/products/pos-retail-shops",
    component: createAsync("POSRetailShops"),
    exact: true,
  },
  {
    path: "/service/wearable-app-development-services",
    component: createAsync("WearableAppDevelopment"),
    exact: true,
  },
  {
    path: "/staff-augmentation-solution",
    component: createAsync("StaffAugmentationSolution"),
    exact: true,
  },
  {
    path: "/service/web-development-services",
    component: createAsync("WebDevelopmentSolutions"),
    exact: true,
  },
  {
    path: "/java-web-development-solution",
    component: createAsync("JavaWebDevelopment"),
    exact: true,
  },
  {
    path: "/dotnet-web-development-solution",
    component: createAsync("DotNetWebDevelopment"),
    exact: true,
  },
  {
    path: "/angular-web-development-solution",
    component: createAsync("AngularWebDevelopment"),
    exact: true,
  },
  {
    path: "/service/ecommerce-web-development-services",
    component: createAsync("EcommerceWebDevelopment"),
    exact: true,
  },
  {
    path: "/Php-Web-Development-solution",
    component: createAsync("PhpWebDevelopment"),
    exact: true,
  },
  {
    path: "/Laravel-Web-Development-solution",
    component: createAsync("LaravelWebDevlopment"),
    exact: true,
  },
  {
    path: "/products/fixjob-job-portal",
    component: createAsync("FixjobJobPortal"),
    exact: true,
  },
  {
    path: "/service/technology-consulting-services",
    component: createAsync("TechnologyConsulting"),
    exact: true,
  },
  {
    path: "/service/data-migration-services",
    component: createAsync("DataMigration"),
    exact: true,
  },
  {
    path: "/products/uber",
    component: createAsync("Uber"),
    exact: true,
  },
  {
    path: "/industries",
    component: createAsync("Industry"),
    exact: true,
  },
  {
    path: "/industries/:id",
    component: createAsync("IndustryInner"),
    exact: true,
  },
  {
    path: "/blog",
    component: createAsync("Blog"),
    exact: true,
  },
  {
    path: "/blog/bloginner/:id",
    component: createAsync("BlogInner"),
    exact: true,
  },

  {
    path: "/backend/python-backend-development-technology",
    component: createAsync("PythonDevelopment"),
    exact: true,
  },
  {
    path: "/backend/dotnet-backend-development-technology",
    component: createAsync("DotNetDevelopment"),
    exact: true,
  },
  {
    path: "/backend/php-backend-development-technology",
    component: createAsync("PhpDevelopment"),
    exact: true,
  },
  {
    path: "/backend/java-backend-development-technology",
    component: createAsync("JavaDevelopment"),
    exact: true,
  },
  {
    path: "/backend/nodeJs-backend-development-technology",
    component: createAsync("NodeJsDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/reactnative-frontend-development-technology",
    component: createAsync("ReactNativeFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/angular-frontend-development-technology",
    component: createAsync("AngularFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/vueJs-frontend-development-technology",
    component: createAsync("VueJsFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/ios-frontend-development-technology",
    component: createAsync("IOSFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/android-frontend-development-technology",
    component: createAsync("AndroidFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/flutter-frontend-development-technology",
    component: createAsync("FlutterFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/xamarin-frontend-development-technology",
    component: createAsync("XamarinFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frontend/reactJs-frontend-development-technology",
    component: createAsync("ReactJsFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/frameworks/laravel-framework-technology",
    component: createAsync("LaravelFramework"),
    exact: true,
  },
  {
    path: "/laravel.html",
    component: createAsync("LaravelFramework"),
    exact: true,
  },
  {
    path: "/vaadin.html",
    component: createAsync("VaadinFramework"),
    exact: true,
  },
  {
    path: "/codeignitor.html",
    component: createAsync("CodeIgnitorFramework"),
    exact: true,
  },
  {
    path: "/ionic.html",
    component: createAsync("IonicMobileAppDevelopment"),
    exact: true,
  },
  {
    path: "/yii.html",
    component: createAsync("YiiFramework"),
    exact: true,
  },
  {
    path: "/symfony.html",
    component: createAsync("SymfonyFramework"),
    exact: true,
  },
  {
    path: "/native.html",
    component: createAsync("ReactNativeFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/who-we-are.html",
    component: createAsync("WhoWeAre"),
    exact: true,
  },
  {
    path: "/our-team.html",
    component: createAsync("WhoWeAre"),
    exact: true,
  },
  {
    path: "/cocos2d.html",
    component: createAsync("Cocos2dFramework"),
    exact: true,
  },
  {
    path: "/xamarin.html",
    component: createAsync("XamarinFrontendDevelopment"),
    exact: true,
  },
  {
    path: "/jsf.html",
    component: createAsync("JSFDevelopment"),
    exact: true,
  },
  {
    path: "/cordova.html",
    component: createAsync("CordovaFramework"),
    exact: true,
  },
  {
    path: "/cms.html",
    component: createAsync("CmsFramework"),
    exact: true,
  },
  {
    path: "/phalcon.html",
    component: createAsync("PhalconFramework"),
    exact: true,
  },
  {
    path: "/titanium.html",
    component: createAsync("TitaniumFramework"),
    exact: true,
  },
  {
    path: "/payroll.html",
    component: createAsync("PayrollSystem"),
    exact: true,
  },
  {
    path: "/struts-2.html",
    component: createAsync("Struts2Framework"),
    exact: true,
  },
  {
    path: "/struts-1.html",
    component: createAsync("Struts1Framework"),
    exact: true,
  },
  {
    path: "/spring-mvc.html",
    component: createAsync("SpringMvcFramework"),
    exact: true,
  },
  {
    path: "/organizational-structure.html",
    component: createAsync("OrganizationalStructure"),
    exact: true,
  },
  {
    path: "/inventory-management.html",
    component: createAsync("InventoryManagement"),
    exact: true,
  },
  {
    path: "/phone-gap.html",
    component: createAsync("PhoneGapFramework"),
    exact: true,
  },
  {
    path: "/engagement-models.html",
    component: createAsync("EngagementModels"),
    exact: true,
  },
  {
    path: "/intel-xdk.html",
    component: createAsync("IntelXdk"),
    exact: true,
  },
  {
    path: "/nop-commerce.html",
    component: createAsync("NopCommerce"),
    exact: true,
  },
  {
    path: "/ruby-on-rails.html",
    component: createAsync("RubyOnRails"),
    exact: true,
  },
  {
    path: "/customized-software-development.html",
    component: createAsync("CustomSoftwareDevelopment"),
    exact: true,
  },
  {
    path: "/manufacturing-resource-planning.html",
    component: createAsync("ManufactureResourcePlanning"),
    exact: true,
  },
  {
    path: "/human-resource-management.html",
    component: createAsync("HumanResourceManagement"),
    exact: true,
  },
  {
    path: "/customer-relation-management.html",
    component: createAsync("CustomerRelationManagement"),
    exact: true,
  },
  {
    path: "/application-support-and-maintenance.html",
    component: createAsync("ApplicationSupportAndMaintenance"),
    exact: true,
  },
  {
    path: "/business-process-re-engineering.html",
    component: createAsync("BusinessProcessReEngineering"),
    exact: true,
  },
  {
    path: "/web-content-management-solutions.html",
    component: createAsync("WebContentManagementSolution"),
    exact: true,
  },
  {
    path: "/web-applications-and-web-portals.html",
    component: createAsync("WebApplicationsAndWebPortals"),
    exact: true,
  },
  {
    path: "/quality-process.html",
    component: createAsync("QualityProcess"),
    exact: true,
  },
  {
    path: "/contact-us.html",
    component: createAsync("Contact"),
    exact: true,
  },
  {
    path: "/contact.html",
    component: createAsync("Contact"),
    exact: true,
  },
  {
    path: "/email",
    component: createAsync("Contact"),
    exact: true,
  },
  {
    path: "/web-development-consulting.html",
    component: createAsync("WebDevelopmentSolutions"),
    exact: true,
  },
  {
    path: "/frameworks/symfony-framework-tttechnology",
    component: createAsync("SymfonyFramework"),
    exact: true,
  },
  {
    path: "/frameworks/django-framework-technology",
    component: createAsync("DjangoFramework"),
    exact: true,
  },
  {
    path: "/frameworks/mongodb-framework-technology",
    component: createAsync("MongoDBFramework"),
    exact: true,
  },
  {
    path: "/frameworks/mean-framework-technology",
    component: createAsync("MEANDevelopment"),
    exact: true,
  },
  {
    path: "/frameworks/mern-framework-technology",
    component: createAsync("MERNDevelopment"),
    exact: true,
  },
  {
    path: "/Process",
    component: createAsync("Process"),
    exact: true,
  },
  {
    path: "/Team",
    component: createAsync("Team"),
    exact: true,
  },
  // {
  //   path: "/Event",
  //   component: createAsync("Event"),
  //   exact: true,
  // },
  {
    path: "/product",
    component: createAsync("Product"),
    exact: true,
  },
  {
    path: "/case-studies",
    component: createAsync("CaseStudies"),
    exact: true,
  },
  {
    path: "/case-studies/automobile-dealership-management-erp",
    component: createAsync("AgwanCase"),
    exact: true,
  },
  {
    path: "/case-studies/machines-management-erp",
    component: createAsync("FilsilpekCase"),
    exact: true,
  },
  {
    path: "/case-studies/property-management-erp",
    component: createAsync("MyPropertyCase"),
    exact: true,
  },
  {
    path: "/case-studies/automobiles-management-erp",
    component: createAsync("TvsCase"),
    exact: true,
  },
  {
    path: "/case-studies/real-estate-management-erp",
    component: createAsync("SheetalCase"),
    exact: true,
  },
  {
    path: "/case-studies/lab-operations-management-erp",
    component: createAsync("ShukraCase"),
    exact: true,
  },
  {
    path: "/case-studies/automation-solution-management-erp",
    component: createAsync("SheerangCase"),
    exact: true,
  },
  {
    path: "/case-studies/construction-management-erp",
    component: createAsync("ChetanCase"),
    exact: true,
  },
  {
    path: "/case-studies/real-estate-management-system-erp",
    component: createAsync("NestoriaCase"),
    exact: true,
  },
  {
    path: "/case-studies/construction-management-system-erp",
    component: createAsync("SPGCase"),
    exact: true,
  },
  {
    path: "/case-studies/infrastructure-management-erp",
    component: createAsync("HrSpacesCase"),
    exact: true,
  },
  {
    path: "/case-studies/biomedicals-management-system-erp",
    component: createAsync("AgdCase"),
    exact: true,
  },

  {
    path: "/case-studies/pharmaceuticals-management-system-erp",
    component: createAsync("VanvatCase"),
    exact: true,
  },
  {
    path: "/case-studies/bakery-management-system-erp",
    component: createAsync("FreshCase"),
    exact: true,
  },
  {
    path: "/case-studies/automobiles-management-system-erp",
    component: createAsync("ChetakCase"),
    exact: true,
  },
  {
    path: "/case-studies/garments&fabrics-management-erp",
    component: createAsync("SpknitCase"),
    exact: true,
  },
  {
    path: "/case-studies/real-estate-management-system-erp",
    component: createAsync("ShriParshvaCase"),
    exact: true,
  },
  {
    path: "/case-studies/CaseStudiesInner/:id",
    component: createAsync("CaseStudiesInner"),
    exact: true,
  },
  {
    path: "/service/wallet-blockchain-development-services",
    component: createAsync("WalletDevelopment"),
    exact: true,
  },
  {
    path: "/service/crypto-exchange-blockchain-development-services",
    component: createAsync("CryptoExchange"),
    exact: true,
  },
  {
    path: "/service/ethereum-blockchain-development-services",
    component: createAsync("EthereumDevelopment"),
    exact: true,
  },
  {
    path: "/service/hyperledger-blockchain-development-services",
    component: createAsync("HyperledgerDevelopment"),
    exact: true,
  },
  {
    path: "/service/private-blockchain-development-services",
    component: createAsync("PrivateBlockchain"),
    exact: true,
  },
  {
    path: "/service/salesforce-development-services",
    component: createAsync("SalesforceDevService"),
    exact: true,
  },
  {
    path: "/service/salesforce-consulting-services",
    component: createAsync("SalesforceConsulting"),
    exact: true,
  },
  {
    path: "/service/salesforce-implementation-services",
    component: createAsync("SalesforceImplementation"),
    exact: true,
  },
  {
    path: "/service/AI-object-recognition-services",
    component: createAsync("ObjectRecognition"),
    exact: true,
  },
  {
    path: "/service/AI-text-to-speech-services",
    component: createAsync("TextToSpeech"),
    exact: true,
  },
  {
    path: "/service/AI-business-intelligence-services",
    component: createAsync("BusinessIntelligence"),
    exact: true,
  },
  {
    path: "/service/AI-data-forecasting-services",
    component: createAsync("DataForecasting"),
    exact: true,
  },
  {
    path: "/service/AI-data-analytics-services",
    component: createAsync("DataAnalytics"),
    exact: true,
  },
  {
    path: "/service/AI-sentimental-analysis-services",
    component: createAsync("SentimentalAnalysis"),
    exact: true,
  },
  {
    path: "/service/AI-alexa-skill-development-services",
    component: createAsync("AlexaSkillDevelopment"),
    exact: true,
  },

  {
    path: "/Portfolio-2col",
    component: createAsync("Portfolio-2col"),
    exact: true,
  },
  {
    path: "/Portfolio-3col",
    component: createAsync("Portfolio-3col"),
    exact: true,
  },
  {
    path: "/Portfolio-fullwidth-4col",
    component: createAsync("Portfolio-fullwidth-4col"),
    exact: true,
  },
  {
    path: "/portfolio",
    component: createAsync("PortfolioSingle"),
    exact: true,
  },
  {
    path: "/portfolio/cubevo-erp",
    component: createAsync("Cubevo"),
    exact: true,
  },
  {
    path: "/portfolio/zoomative-erp",
    component: createAsync("Zoomative"),
    exact: true,
  },
  {
    path: "/portfolio/radius-erp",
    component: createAsync("RadiusHr"),
    exact: true,
  },
  {
    path: "/portfolio/billiant-erp",
    component: createAsync("Billiant"),
    exact: true,
  },
  {
    path: "/portfolio/diameco-erp",
    component: createAsync("Diameco"),
    exact: true,
  },
  {
    path: "/portfolio/dimexa-erp",
    component: createAsync("Diamexa"),
    exact: true,
  },
  {
    path: "/portfolio/fabrexa-erp",
    component: createAsync("Fabrexa"),
    exact: true,
  },
  {
    path: "/portfolio/transida-erp",
    component: createAsync("Transida"),
    exact: true,
  },
  {
    path: "/portfolio/medicive-erp",
    component: createAsync("Medicive"),
    exact: true,
  },
  {
    path: "/portfolio/construx-erp",
    component: createAsync("Construx"),
    exact: true,
  },
  {
    path: "/portfolio/scholar-erp",
    component: createAsync("Scholar"),
    exact: true,
  },
  {
    path: "/portfolio/ignitive-erp",
    component: createAsync("Ignitive"),
    exact: true,
  },

  {
    path: "/Contact",
    component: createAsync("Contact"),
    exact: true,
  },
  {
    path: "/Faq",
    component: createAsync("Faq"),
    exact: true,
  },
  {
    path: "/ServiceDetails",
    component: createAsync("ServiceDetails"),
    exact: true,
  },
  {
    component: createAsync("404"),
    exact: true,
  },
];
export default routes;

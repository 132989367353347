import React, { Component } from "react";
const baseUrl = process.env.REACT_APP_BASE_URL;
// import Animated from "../../assets/images/Animated_Loader_Jstechno.svg";

export default class Preloader extends Component {
  render() {
    return (
      <>
        <div className="preloader_logo">
          <object type="image/svg+xml" data={`${baseUrl}assets/images/Animated_Loader_Jstechno.svg`}>
            svg-animation
          </object>
        </div>
      </>
    );
  }
}

import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";
import Preloader from "./components/Preloader/Preloader";
/*------ Pages-----*/
// import ScrollToTopRoute from "./ScrollToTopRoute";

class App extends Component {
  // componentDidMount() {
  //   this.props.hideLoader();
  // }
  render() {
    return (
      <Router>
        <Suspense fallback={<Preloader />}>
          <Switch>
            {routes.map((props) => {
              return <Route key={`${props.path}`} {...props} />;
              // return <ScrollToTopRoute key={`${props.path}`} {...props} />;
            })}
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default App;
